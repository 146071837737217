'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _tool = require('../../common/tool');

var _tool2 = _interopRequireDefault(_tool);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var NavigationMain = /** @class */function () {
    function NavigationMain() {
        this.productHeaderDom = document.getElementById('product-header');
        this.navigationDom = document.getElementById('bottom-nav');
        this.pathName = decodeURIComponent(window.location.pathname);
        this.init();
    }
    NavigationMain.prototype.init = function () {
        this.jumptoPayPage();
        this.navigationEvent();
        this.toTop();
    };
    NavigationMain.prototype.jumptoPayPage = function () {
        var that = this;
        var hasJumptoPayPageBtn = document.body.contains(document.getElementsByClassName('jumpto-pay-page')[0]);
        if (hasJumptoPayPageBtn) {
            var jumptoPayPageBtns = document.querySelectorAll('.jumpto-pay-page');
            jumptoPayPageBtns.forEach(function (item) {
                item.addEventListener('click', function () {
                    localStorage.setItem('pageSource', JSON.stringify(that.pathName));
                    var gurl = item.getAttribute('data-gurl');
                    window.location.href = "".concat(gurl, "/buy.html");
                });
            });
        }
    };
    NavigationMain.prototype.navigationEvent = function () {
        // 登录页跳转
        var that = this;
        var navUser = _tool2.default.$_select(that.navigationDom, '.nav-user');
        navUser.addEventListener('click', function () {
            if (isLogin == 0) {
                window.location.href = '/userlogin';
            } else if (isLogin == 1) {
                window.location.href = '/user';
            }
        });
        // 菜单活跃状态
        var navLiADom = _tool2.default.$_selectAll(that.navigationDom, '.bottom-nav-box ul li a');
        var navLiDom = _tool2.default.$_selectAll(that.navigationDom, '.bottom-nav-box ul li');
        var flag = false;
        var tmpPathName = '';
        tmpPathName = this.pathName;
        try {
            navLiADom.forEach(function (item) {
                var url = item.getAttribute('data-url');
                var urlArr = url.split('&');
                for (var _i = 0, urlArr_1 = urlArr; _i < urlArr_1.length; _i++) {
                    var uItem = urlArr_1[_i];
                    if (tmpPathName == '/') {
                        tmpPathName = '/home';
                    }
                    if (tmpPathName.includes(uItem)) {
                        navLiDom.forEach(function (nItem) {
                            return nItem.classList.remove('active');
                        });
                        item.parentNode.classList.add('active');
                        flag = true;
                        throw Error();
                    }
                }
                if (!flag) {
                    navLiDom.forEach(function (nItem) {
                        return nItem.classList.remove('active');
                    });
                }
            });
        } catch (e) {
            // console.log(e)
        }
    };
    NavigationMain.prototype.toTop = function () {
        var toTopBoxBtn = document.querySelector('.kingshiper-footer-service');
        var toTopBtn = document.querySelector('.to-top-btn');
        window.addEventListener('scroll', function () {
            var scrollDistance = window.pageYOffset || document.documentElement.scrollTop;
            if (scrollDistance > 150) {
                toTopBoxBtn.classList.add('show');
            } else {
                toTopBoxBtn.classList.remove('show');
            }
        });
        toTopBtn.addEventListener('click', function () {
            window.scrollTo({
                left: 0,
                top: 0,
                behavior: "smooth"
            });
        });
        // 绑定客服事件
        var linKefuBtn = document.querySelector('.user-header-kefu');
        linKefuBtn.addEventListener("click", function () {
            window.open(ysf('url'));
        });
    };
    return NavigationMain;
}();
exports.default = NavigationMain;